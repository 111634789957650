import React from 'react';

const Instagram = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 39.761 39.761">
  <g id="Group_145" data-name="Group 145" transform="translate(-46.961 -2986.723)">
    <path id="Path_95" data-name="Path 95" d="M86.711,2996.349l.011,20.486c-.066.244-.143.485-.2.732a10.581,10.581,0,0,1-5.432,7.474,27.03,27.03,0,0,1-4,1.431l-20.486.012c-.243-.066-.482-.146-.728-.2a10.57,10.57,0,0,1-7.477-5.428,26.623,26.623,0,0,1-1.432-4l-.012-20.486c.067-.244.146-.485.2-.731a10.564,10.564,0,0,1,5.43-7.474,27.074,27.074,0,0,1,4-1.432l20.486-.012c.192.057.381.131.577.17a10.544,10.544,0,0,1,7.586,5.39A27.2,27.2,0,0,1,86.711,2996.349Zm-19.892,26.6c2.852,0,5.7,0,8.556-.006a7.481,7.481,0,0,0,7.82-7.842q0-8.517-.01-17.036a7.472,7.472,0,0,0-7.841-7.819q-8.519-.006-17.036.01a7.466,7.466,0,0,0-7.82,7.84q-.007,8.517.011,17.036a7.475,7.475,0,0,0,7.84,7.821C61.166,3022.963,63.993,3022.956,66.819,3022.954Z" fill="#fff"/>
    <path id="Path_96" data-name="Path 96" d="M66.869,3016.848a10.246,10.246,0,1,1,10.217-10.233A10.276,10.276,0,0,1,66.869,3016.848Zm-.032-3.531a6.713,6.713,0,1,0-6.708-6.718A6.762,6.762,0,0,0,66.837,3013.317Z" fill="#fff"/>
    <path id="Path_97" data-name="Path 97" d="M77.573,2998.522a2.574,2.574,0,0,1-.131-5.147,2.613,2.613,0,0,1,2.657,2.593A2.584,2.584,0,0,1,77.573,2998.522Z" fill="#fff"/>
  </g>
</svg>

    )
}

export default Instagram;